import React from "react";
import { useSelector } from "react-redux";
import IconCard from "../iconCard";
import VideoCards from "../videoCard";
import { Box } from "@material-ui/core";
import rca from "../../assets/icons/rca.svg";

const VideoPageSection = () => {
  const { allDoc, allDocLoading, allDocLoadingSucess } = useSelector(
    (state) => state.imageUpload
  );
  return (
    <>
      {" "}
      {allDocLoading && "loading.."}
      {allDocLoadingSucess && (
        <IconCard count={allDoc.length} text="Total Items" icon={rca} />
      )}
      <Box pt={5} pb={3}>
        <VideoCards />
      </Box>
    </>
  );
};

export default VideoPageSection;
