import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { TextField, Button, Typography } from "@material-ui/core";

import { Form, Formik, useFormik } from "formik";
import UploadImagesBox from "../uploadImagesBox";
import SingleImageUpload from "../singleImageUpload";
import { useDispatch } from "react-redux";
import {
  addImageUpload,
  allDocument,
} from "../../features/imageUpload/imageUpload.action";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 30,
    position: "absolute",
    width: 800,
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: 30,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
  },
  formWrapper: {
    display: "grid",
    gap: 20,
    width: "100%",
  },
}));

export default function FormModal({ open, close }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const initialValues = {
    title: "",
    desc: "",
    coverImage: null,
    images: [],
  };

  const handleSubmit = async (values, { resetForm }) => {
    console.log(values, "values");
    const fd = new FormData();
    fd.append("title", values.title);
    fd.append("description", values.desc);
    fd.append("coverImage", values.coverImage);
    values.images.forEach((image, index) => {
      fd.append("images", image);
    });
    await dispatch(addImageUpload(fd));
    await resetForm();
    await dispatch(allDocument());
    close();
  };

  const body = (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formik) => (
        <Form>
          <div className={classes.paper}>
            <div>
              <Typography variant="h1">Upload Image Form</Typography>
            </div>
            <div>
              <SingleImageUpload formik={formik} />
            </div>
            <div className={classes.formWrapper}>
              <UploadImagesBox formik={formik} edit={false} />
              <TextField
                id="title"
                name="title"
                label="Title"
                variant="outlined"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                fullWidth
              />
              <TextField
                id="desc"
                name="desc"
                label="Description"
                multiline
                variant="outlined"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                fullWidth
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={formik.handleSubmit}
              >
                Done
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

  const handleBackdropClick = () => {
    // resetForm();
    close();
  };

  return (
    <>
      <Modal
        open={open}
        onScroll="body"
        BackdropProps={{
          onClick: handleBackdropClick,
        }}
      >
        {body}
      </Modal>
    </>
  );
}
