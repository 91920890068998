import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Typography, Paper, Grid, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { BASE_URL } from "../../htttp/urls";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    outline: "none",
  },
  coverImage: {
    width: "100%",
    height: "200px",
    objectFit: "cover",
    borderRadius: theme.spacing(1),
  },
  imagesContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
  },
  image: {
    width: "100px",
    height: "auto",
    borderRadius: theme.spacing(1),
  },
}));

const ViewModal = ({ open, onClose, data, plan }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      {plan ? (
        <Paper className={classes.paper}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h2" gutterBottom>
            Title: {data.title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            ID: {data._id}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Description: {data.description}
          </Typography>
          <Typography variant="h2" gutterBottom>
            Cover Image
          </Typography>
          <img src={data.imageUrl} alt="" className={classes.coverImage} />
        </Paper>
      ) : (
        <Paper className={classes.paper}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h2" gutterBottom>
            Title: {data.title}
          </Typography>
          <Typography variant="body1" gutterBottom>
            ID: {data._id}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Description: {data.description}
          </Typography>
          <Typography variant="h2" gutterBottom>
            Cover Image
          </Typography>
          <img
            src={`${BASE_URL}/${data.coverImage}`}
            alt=""
            className={classes.coverImage}
          />
          <Typography variant="h2" gutterBottom>
            Images
          </Typography>
          <div className={classes.imagesContainer}>
            {data.images.map((image, index) => (
              <img
                key={index}
                src={`${BASE_URL}/${image?.imageUrl}`}
                alt=""
                className={classes.image}
              />
            ))}
          </div>
        </Paper>
      )}
    </Modal>
  );
};

export default ViewModal;
