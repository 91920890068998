import { createAsyncThunk } from "@reduxjs/toolkit";
import { ChangePassword, deletePlanById, getAllPlans, planForm } from "./api";
import toast from "react-hot-toast";

export const addPlanUpload = createAsyncThunk(
  "addPlanUpload/uploadPlan",
  async (payload) => {
    const resultPromise = new Promise((resolve, reject) => {
      planForm(payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch(({ response }) => {
          reject(response.data?.error || "Something went wrong");
        });
    });

    toast.promise(resultPromise, {
      loading: "Adding Form...",
      success: "Added Form successfully",
      error: (err) => err,
    });

    return await resultPromise;
  }
);

export const allDocument = createAsyncThunk(
  "getAllPlans/docs",
  async (payload) => {
    try {
      const res = await getAllPlans(payload);
      return res.data;
    } catch (err) {
      return err.response;
    }
  }
);

//delete in
export const deleteDoc = createAsyncThunk(
  "deletePlanById/deleteDocument",
  async (payload) => {
    const resultPromise = new Promise((resolve, reject) => {
      deletePlanById(payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch(({ response }) => {
          reject(response.data?.error || "Something went wrong");
        });
    });

    toast.promise(resultPromise, {
      loading: "Deleting Form...",
      success: "Delete Form successfully",
      error: (err) => err,
    });

    return await resultPromise;
  }
);

export const changePass = createAsyncThunk(
  "changePass/passChange",
  async (payload) => {
    const resultPromise = new Promise((resolve, reject) => {
      ChangePassword(payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch(({ response }) => {
          reject(response.data?.error || "Something went wrong");
        });
    });

    toast.promise(resultPromise, {
      loading: "Changing Password...",
      success: "Password Changed successfully",
      error: (err) => err,
    });

    return await resultPromise;
  }
);
