import { PDE } from "../../htttp/urls";

export const planForm = async (payload) => {
  const res = await PDE.post("api/v1/plan", payload, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("AdminHeaven")}`,
    },
  });
  return res;
};

export const getAllPlans = async () => {
  const res = await PDE.get("api/v1/plans");
  return res;
};

export const deletePlanById = async (id) => {
  const res = await PDE.delete(`api/v1/plan/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("AdminHeaven")}`,
    },
  });
  return res;
};

export const ChangePassword = async (payload) => {
  const res = await PDE.put(`api/v1/password/${payload.id}`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("AdminHeaven")}`,
    },
  });
  return res;
};
