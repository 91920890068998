import React, { useEffect, useState } from "react";
import { Box, TableCell, TableRow } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import pic from "../../assets/image/ava.png";
import Button from "../buttons";
import BasicTable from "../table";
// Import the ViewModal component
import { useDispatch } from "react-redux";
import {
  allDocument,
  deleteDoc,
} from "../../features/imageUpload/imageUpload.action";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../htttp/urls";
import ViewModal from "../singleView";
import DeleteConfirmationModal from "../deleteModal";

const columns = ["Id", "tiltle", "description", "image", "action"];

const VideoCards = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { allDoc, allDocLoading, allDocLoadingSucess } = useSelector(
    (state) => state.imageUpload
  );
  const [selectedVideo, setSelectedVideo] = useState(null); // Track the selected video
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal open/close state

  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const head = columns.map((col, id) => (
    <TableCell key={id} className={classes.col}>
      {col}
    </TableCell>
  ));

  const body = allDoc?.map((row, id) => (
    <TableRow key={id}>
      {/* {row.map((col) => */}
      <TableCell className={classes.col}>{row._id}</TableCell>
      <TableCell className={classes.col}>{row.title}</TableCell>
      <TableCell className={classes.col}>{row.description}</TableCell>
      <TableCell className={classes.col}>
        <img
          src={`${BASE_URL}/${row.coverImage}`}
          alt=""
          width="50px"
          height="50px"
          style={{ borderRadius: "50%" }}
        />
      </TableCell>

      <TableCell>
        <Box className={classes.actionBtn}>
          <Button
            className={classes.btnview}
            color="primary"
            variant="contained"
            onClick={() => handleView(row)} // Pass the row data to handleView
          >
            view
          </Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              setSelectedItemId(row._id);
              setDeleteModalOpen(true);
            }}
          >
            delete
          </Button>
        </Box>
      </TableCell>
    </TableRow>
  ));

  useEffect(() => {
    dispatch(allDocument());
  }, [dispatch]);

  const handleView = (videoData) => {
    setSelectedVideo(videoData);
    setIsModalOpen(true);
  };

  const handleDelete = async () => {
    if (selectedItemId) {
      await dispatch(deleteDoc(selectedItemId));
      await dispatch(allDocument());
      await setDeleteModalOpen(false);
    }
  };

  return (
    <>
      {allDocLoading && "loading..."}
      {allDocLoadingSucess && (
        <>
          <BasicTable
            tableName="Our Videos"
            head={head}
            body={body}
            addBtn="Add"
          />

          {isModalOpen && (
            <ViewModal
              open={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              data={selectedVideo}
            />
          )}

          {isDeleteModalOpen && (
            <DeleteConfirmationModal
              open={isDeleteModalOpen}
              onClose={() => setDeleteModalOpen(false)}
              onDelete={handleDelete}
            />
          )}
        </>
      )}
    </>
  );
};

export default VideoCards;

const useStyles = makeStyles((theme) => ({
  textcell: {
    color: theme.palette.color.secondary,
  },
  col: {
    color: theme.palette.color.primary,
  },
  actionBtn: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  btnview: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },
}));
