import { PDE } from "../../htttp/urls";

export const imageUpload = async (payload) => {
  const res = await PDE.post("api/v1/featuredimage", payload, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data;charset=UTF-8",
    },
  });
  return res;
};

export const getAllDocuments = async () => {
  const res = await PDE.get("api/v1/featuredimages");
  return res;
};

export const deleteDocumentById = async (id) => {
  const res = await PDE.delete(`api/v1/featuredimages/${id}`);
  return res;
};
