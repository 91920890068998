import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {
  TextField,
  Button,
  Typography,
  TextareaAutosize,
} from "@material-ui/core";

import { Form, Formik, useFormik } from "formik";
import { useDispatch } from "react-redux";
import {
  addPlanUpload,
  allDocument,
} from "../../features/planForm/planForm.action";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 30,
    position: "absolute",
    width: 800,
    backgroundColor: theme.palette.background.paper,

    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: 30,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
  },
  formWrapper: {
    display: "grid",
    gap: 20,
    width: "100%",
  },
}));

export default function PlanModal({ open, close }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const initialValues = {
    title: "",
    description: "",
    price: "",
    imageUrl: "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    console.log(values, "values");

    await dispatch(addPlanUpload(values));
    await resetForm();
    await dispatch(allDocument());
    close();
  };

  const body = (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formik) => (
        <Form>
          <div className={classes.paper}>
            <div>
              <Typography variant="h1">Upload Plan Form</Typography>
            </div>
            <div className={classes.formWrapper}>
              <TextField
                id="title"
                name="title"
                label="Title"
                variant="outlined"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                fullWidth
              />
              <TextareaAutosize
                id="description"
                name="description"
                label="Description"
                multiline
                placeholder="Enter The Description..."
                variant="outlined"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                fullWidth
                style={{
                  width: "100%",
                  whiteSpace: "pre-wrap",
                  padding: "10px",
                }}
                minRows={5}
              />
              <TextField
                id="imageUrl"
                name="imageUrl"
                label="Image Url"
                variant="outlined"
                type="text"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                fullWidth
              />

              <TextField
                id="price"
                name="price"
                label="Price"
                variant="outlined"
                type="number"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                fullWidth
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={formik.handleSubmit}
              >
                Done
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

  const handleBackdropClick = () => {
    // resetForm();
    close();
  };

  return (
    <>
      <Modal
        open={open}
        onScroll="body"
        BackdropProps={{
          onClick: handleBackdropClick,
        }}
      >
        {body}
      </Modal>
    </>
  );
}
