import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "../components/footer";
import Header from "../components/header";

import Dashboard from "../pages/dashboard";
import Login from "../pages/login";
import PrivateRoute from "../components/privateRoute";
import VideoPageSection from "../components/videoPageSection.js";
import Plan from "../components/plan/index.js";
import ChangePassword from "../components/change-password/index.js";
const Routing = () => {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" exact element={<Login />} />
        <Route
          path="/dashboard"
          exact
          element={
            <PrivateRoute>
              <Dashboard>
                <VideoPageSection />
              </Dashboard>
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/add-plan"
          exact
          element={
            <PrivateRoute>
              <Dashboard>
                <Plan />
              </Dashboard>
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard/change-password"
          exact
          element={
            <PrivateRoute>
              <Dashboard>
                <ChangePassword />
              </Dashboard>
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default Routing;
