import React, { useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, Card, CardMedia } from "@material-ui/core";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  input: {
    display: "none",
  },
  card: {
    width: "100%",
    height: "200px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardMedia: {
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
  uploadButton: {
    marginTop: theme.spacing(2),
  },
  uploadRemoveButton: {
    marginTop: theme.spacing(2),
    position: "absolute",
    top: 0,
    right: 5,
  },
  icon: {
    fontSize: "40px",
  },
}));

const SingleImageUpload = ({ formik }) => {
  const classes = useStyles();
  const fileInputRef = useRef(null);

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith("image/") && file.size <= 5000000) {
      formik.setFieldValue("coverImage", file);
    }
  };

  const handleRemoveImage = () => {
    formik.setFieldValue("coverImage", null);
  };

  return (
    <div className={classes.container}>
      <input
        id="image-upload-button"
        type="file"
        ref={fileInputRef}
        className={classes.input}
        name="image"
        onChange={handleFileInputChange}
        accept="image/*"
      />
      <Card className={classes.card}>
        {formik.values.coverImage ? (
          <>
            <CardMedia
              className={classes.cardMedia}
              image={URL.createObjectURL(formik.values.coverImage)}
              title="Uploaded Image"
            />
            <Button
              className={classes.uploadRemoveButton}
              onClick={handleRemoveImage}
              variant="contained"
              color="secondary"
            >
              Remove
            </Button>
          </>
        ) : (
          <>
            <label htmlFor="image-upload-button">
              <Button
                component="span"
                className={classes.uploadButton}
                variant="contained"
                color="primary"
              >
                <PhotoCameraIcon className={classes.icon} />
                Upload Cover Image
              </Button>
            </label>
          </>
        )}
      </Card>
    </div>
  );
};

export default SingleImageUpload;
